<template>
  <div>
    <!-- Form: Professional Infos Form -->
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="UserIcon" size="19" />
          <h4 class="mb-0 ml-50">Informations Professionnelles</h4>
        </div>
        <b-row class="mt-1">
          <!-- Field: Job Title -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="job_title"
              rules="required"
            >
              <b-form-group label="Poste Occupé :" label-for="job_title">
                <b-form-input
                  id="job_title"
                  v-model="employee.job_title"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Poste occupé..."
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Contract type -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="Contrat"
              rules="required"
            >
              <b-form-group label="Contrat :" label-for="contract">
                <v-select
                  v-model="employee.contract_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="contractOptions"
                  :reduce="(val) => val.value"
                  :selectable="
                    (option) => !option.value.includes('nothing_selected')
                  "
                  :clearable="false"
                  input-id="contract"
                  placeholder="Sélectionner le type de contrat..."
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Email -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="email"
              rules="required|email"
            >
              <b-form-group label="E-mail :" label-for="email">
                <b-form-input
                  id="email"
                  v-model="employee.email"
                  type="email"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="E-mail..."
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Phone -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group label="Téléphone :" label-for="phone">
                <b-form-input
                  id="phone"
                  v-model="employee.phone"
                  :state="getValidationState(validationContext)"
                  placeholder="Téléphone..."
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon icon="CropIcon" size="19" />
          <h4 class="mb-0 ml-50">Administration</h4>
        </div>

        <!-- Field: Employee department -->
        <b-row class="mt-1">
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="department"
              rules="required"
            >
              <b-form-group
                label="Département :"
                label-for="employee-department"
              >
                <v-select
                  v-model="employee.department"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="departmentOptions"
                  :reduce="(val) => val.uid"
                  label="name"
                  :clearable="false"
                  input-id="employee-department"
                  @input="getOfficeOptions"
                  placeholder="Sélectionner le département..."
                  :selectable="(option) => option.uid != null"
                  ><!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    Aucun département disponible
                  </template>
                </v-select>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Employee office -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="office"
              rules=""
            >
              <b-form-group
                label="Service :"
                label-for="employee-office"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="employee.service"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="officeOptions"
                  :reduce="(val) => val.uid"
                  :clearable="true"
                  input-id="employee-office"
                  :selectable="
                    (option) => !option.value.includes('nothing_selected')
                  "
                  placeholder="Sélectionner le service..."
                  ><!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    Aucun service disponible
                  </template></v-select
                >
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              type="submit"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="invalid || isLoading"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              Mettre à jour
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="isLoading"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from "bootstrap-vue";

import { ref, watch } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import vSelect from "vue-select";

import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import useEmployeesList from "../employees-list/useEmployeesList";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  name: "EmployeeEditTabInformation",
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    BButton,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const { contractOptions, departmentOptions, officeOptions, fetchEmployeeProperties, getOfficeOptions } =
      useEmployeesList();
    const toast = useToast();
    const isLoading = ref(false);

    // employee data
    const employee = ref(JSON.parse(JSON.stringify(props.employeeData)));

    // get all properties related to the employee
    fetchEmployeeProperties(["departments"]);

    // reset form
    const resetEmployeeData = () => {
      employee.value = JSON.parse(JSON.stringify(props.employeeData));
    };

    // handle form validation
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetEmployeeData);

    // show notification with message of success or error
    const showToast = (messages, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: messages,
          icon: icon,
          variant: variant,
        },
      });
    };

    const handleSuccess = () => {
      emit("employee-updated");
      showToast("Employé(e) mise à jour avec succès.", "CheckIcon", "success");
    };

    const handleErrors = (errors) => {
      for (const [field, message] of Object.entries(errors)) {
        refFormObserver.value.setErrors({ [field]: message[0] });
      }
    };

    const onSubmit = async () => {
      let isValid = refFormObserver.value.validate();
      if (!isValid) return;

      isLoading.value = true;

      const payload = {
        uid: router.currentRoute.params.id,
        employee: {
          edit_type: "professional_infos",
          job_title: employee.value.job_title,
          contract_type: employee.value.contract_type,
          phone: employee.value.phone,
          email: employee.value.email,
          department: employee.value.department,
          office: employee.value.office,
        },
      };

      try {
        const response = await store.dispatch(
          "employee/updateEmployee",
          payload
        );

        if (!response.success) {
          handleErrors(response.errors);
          showToast(
            "Problème de la mise à jour de l'employé(e).",
            "AlertCircleIcon",
            "danger"
          );
          return;
        }
        handleSuccess();
      } catch (error) {
        showToast(
          "Une erreur est survenue lors de la mise à jour l'employé(e).",
          "AlertCircleIcon",
          "danger"
        );
      } finally {
        isLoading.value = false;
      }
    };

    watch(
      props.employeeData,
      () => {
        resetForm();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      employee,
      isLoading,
      refFormObserver,
      officeOptions,
      contractOptions,
      departmentOptions,

      onSubmit,
      resetForm,
      getOfficeOptions,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
