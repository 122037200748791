<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="employeeData.avatar"
          :text="
            avatarText(employeeData.last_name + ' ' + employeeData.first_name)
          "
          :variant="`light-${resolveEmployeeStatusVariant(
            employeeData.contract_type
          )}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ employeeData.last_name + " " + employeeData.first_name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()" disabled>
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">Charger</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1" disabled>
          <span class="d-none d-sm-inline">Retirer</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Field: Last-name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="last_name"
              rules="required"
            >
              <b-form-group label="Nom de l'employé :" label-for="last-name">
                <b-form-input
                  id="last-name"
                  v-model="employee.last_name"
                  :state="getValidationState(validationContext)"
                  placeholder="Nom de l'employé(e)..."
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: First-name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="first_name"
              rules="required"
            >
              <b-form-group
                label="Prénom de l'employé :"
                label-for="first-name"
              >
                <b-form-input
                  id="first-name"
                  v-model="employee.first_name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Prénom..."
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Sex -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="sex"
              rules="required"
            >
              <b-form-group label="Sexe :" label-for="sex">
                <b-form-radio-group
                  id="gender"
                  v-model="employee.sex"
                  :options="genderOptions"
                  value="male"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- birth date -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="birth_date"
              rules="required"
            >
              <b-form-group
                label="Date de naissance :"
                label-for="birth_date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  id="birth_date"
                  v-model="birthDateFormatted"
                  :config="flatConfig"
                  class="form-control"
                  placeholder="Date de naissance..."
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- address -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="address"
              rules=""
            >
              <b-form-group label="Adresse :" label-for="address">
                <b-form-textarea
                  id="address"
                  v-model="employee.address"
                  :state="getValidationState(validationContext)"
                  placeholder="Adresse..."
                  rows="1"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              type="submit"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="invalid || isLoading"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              Mettre à jour
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="isLoading"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup,
  BForm,
  BFormInvalidFeedback,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { avatarText } from "@core/utils/filter";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";

import useEmployeesList from "../employees-list/useEmployeesList";

import { ref, computed, watch } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "EmployeeEditTabIdentification",
  components: {
    BButton,
    BSpinner,
    BMedia,
    BAvatar,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormInvalidFeedback,

    flatPickr,
    // vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const { resolveEmployeeStatusVariant, genderOptions } = useEmployeesList();
    const toast = useToast();
    const isLoading = ref(false);

    // employee data
    const employee = ref(JSON.parse(JSON.stringify(props.employeeData)));

    // flag config to format date
    const flatConfig = ref({
      wrap: true,
      altFormat: "d-m-Y",
      dateFormat: "d-m-Y",
      // defaultDate: new Date(),
      locale: French, // locale for this instance only
    });

    // format input of user
    const birthDateFormatted = computed({
      get() {
        return moment(employee.value.birth_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
      },
      set(val) {
        employee.value.birth_date = moment(val, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        );
      },
    });

    // reset form
    const resetEmployeeData = () => {
      employee.value = JSON.parse(JSON.stringify(props.employeeData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetEmployeeData);

    const showToast = (messages, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: messages,
          icon: icon,
          variant: variant,
        },
      });
    };

    const handleSuccess = () => {
      emit("employee-updated");
      showToast("Employé(e) mise à jour avec succès.", "CheckIcon", "success");
    };

    const handleErrors = (errors) => {
      for (const [field, message] of Object.entries(errors)) {
        refFormObserver.value.setErrors({ [field]: message[0] });
      }
    };

    const onSubmit = async () => {
      let isValid = refFormObserver.value.validate();
      if (!isValid) return;

      isLoading.value = true;

      const payload = {
        uid: router.currentRoute.params.id,
        employee: {
          edit_type: "identification",
          last_name: employee.value.last_name,
          first_name: employee.value.first_name,
          sex: employee.value.sex,
          birth_date: employee.value.birth_date,
          address: employee.value.address,
        },
      };

      try {
        const response = await store.dispatch(
          "employee/updateEmployee",
          payload
        );

        if (!response.success) {
          handleErrors(response.errors);
          showToast(
            "Problème de la mise à jour de l'employé(e).",
            "AlertCircleIcon",
            "danger"
          );
          return;
        }
        handleSuccess();
      } catch (error) {
        showToast(
          "Une erreur est survenue lors de la mise à jour l'employé(e).",
          "AlertCircleIcon",
          "danger"
        );
      } finally {
        isLoading.value = false;
      }
    };

    watch(
      props.employeeData,
      () => {
        resetForm();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.employeeData.avatar = base64;
      }
    );

    return {
      isLoading,
      resolveEmployeeStatusVariant,
      flatConfig,
      avatarText,
      genderOptions,
      employee,
      birthDateFormatted,

      onSubmit,
      refFormObserver,
      resetForm,
      getValidationState,
      required,

      //  ? Demo - Update Image on click of update button
      refInputEl,

      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
