<template>
  <div>
    <!-- Form: Contract Form -->
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Field: Employee Type -->
          <b-col cols="12" md="4" v-if="isLimitedContract">
            <validation-provider
              #default="validationContext"
              name="employee_type"
              rules="required"
            >
              <b-form-group label="Type :" label-for="employee_type">
                <b-form-checkbox
                  v-model="employee.employee_type"
                  value="contractor"
                  unchecked-value="agent"
                  class="custom-control-warning"
                >
                  <span class="text-bold">Prestataire</span>
                </b-form-checkbox>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: salary -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="salary"
              rules="required"
            >
              <b-form-group
                label="Salaire :"
                label-for="salary"
                :state="getValidationState(validationContext)"
              >
                <b-input-group prepend="XOF" append=".00">
                  <b-form-input
                    id="salary"
                    v-model="employee.salary"
                    type="number"
                    placeholder="Salaire..."
                  />
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Grade -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="grade"
              rules="required"
            >
              <b-form-group label="Niveau d'Étude :" label-for="grade">
                <b-form-input
                  id="grade"
                  v-model="employee.grade"
                  :state="getValidationState(validationContext)"
                  placeholder="Niveau d'Étude..."
                />
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Hire date -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="hire_date"
              rules="required"
            >
              <b-form-group label="Date d'embauche" label-for="hire_date">
                <flat-pickr
                  id="hire_date"
                  v-model="hireDate"
                  :config="flatConfig"
                  class="form-control"
                  placeholder="Date d'embauche'..."
                />

                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Contract start date -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="contract_start_date"
              rules="required"
            >
              <b-form-group
                label="Date de début"
                label-for="contract_start_date"
              >
                <flat-pickr
                  id="contract_start_date"
                  v-model="startDate"
                  :config="flatConfig"
                  class="form-control"
                  placeholder="Date de début contrat..."
                />

                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Contract end date -->
          <b-col cols="12" md="4" v-if="isLimitedContract">
            <validation-provider
              #default="validationContext"
              name="contract_end_date"
              rules="required"
            >
              <b-form-group label="Date de fin" label-for="contract_end_date">
                <flat-pickr
                  id="contract_end_date"
                  v-model="endDate"
                  :config="flatConfig"
                  class="form-control"
                  placeholder="Date de fin contrat..."
                  :disabled="isContractor"
                />

                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              type="submit"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="invalid || isLoading"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              Mettre à jour
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="isLoading"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import { ref, watch, computed, onUpdated, nextTick } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Ripple from "vue-ripple-directive";

import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";

import moment from "moment";
moment.locale("fr");

export default {
  name: "EmployeeEditTabContract",
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BFormInvalidFeedback,

    flatPickr,
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const isLoading = ref(false);
    const flatConfig = ref({
      wrap: true,
      altFormat: "d-m-Y",
      dateFormat: "d-m-Y",
      locale: French, // locale for this instance only
    });

    // employee data
    const employee = ref(JSON.parse(JSON.stringify(props.employeeData)));

    // reset form
    const resetEmployeeData = () => {
      employee.value = JSON.parse(JSON.stringify(props.employeeData));
    };

    // handle form validation
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetEmployeeData);

    // Format all date into final date format
    const formatDate = (date) =>
      moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");

    // add years on current date
    const addYearOnDate = (date, yearNb) => {
      return moment(date, "DD-MM-YYYY")
          .add(yearNb, "years")
          .format("DD-MM-YYYY");
    }

    // Check if employee is in limited contract to show fields (contractor field and contract end date)
    const isLimitedContract = computed(
      () => employee.value.contract_type == "cdd"
    );

    // check if employee is a contractor and be able to set contract end date automatically
    const isContractor = computed(
      () => employee.value.employee_type == "contractor"
    );

    // get directly employee hire date
    const hireDate = computed({
      set(date) {
        employee.value.hire_date = formatDate(date)
      },
      get() {
        return moment(employee.value.hire_date, "YYYY-MM-DD").format("DD-MM-YYYY");
      }
    });

    // get directly employee contract start date
    const startDate = computed({
      set(date) {
        employee.value.contract_start_date = formatDate(date)
      },
      get() {
        return moment(employee.value.contract_start_date, "YYYY-MM-DD").format("DD-MM-YYYY");
      }
    });

    // get directly employee contract end date
    const endDate = computed({
      set(date) {
        employee.value.contract_end_date = formatDate(date)
      },
      get() {
        return moment(employee.value.contract_end_date, "YYYY-MM-DD").format("DD-MM-YYYY");
      }
    });

    // watch start date and then check if employee is a contractor to set contract end date automatically
    watch(startDate, (val) => {
      if (val != null && isContractor.value)
        endDate.value = addYearOnDate(val, 1);
    });

    // watch employee contractor field to see if is toggle to true, set contract end date (if start date is set)
    watch(isContractor, (val) => {
      if (val == true && startDate.value != null) {
        console.log('end date on contractor', addYearOnDate(startDate.value, 1))
        endDate.value = addYearOnDate(startDate.value, 1);
      }
    });

    // show notification with message of success or error
    const showToast = (messages, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: messages,
          icon: icon,
          variant: variant,
        },
      });
    };

    const handleSuccess = () => {
      emit("employee-updated");
      showToast("Employé(e) mise à jour avec succès.", "CheckIcon", "success");
    };

    const handleErrors = (errors) => {
      for (const [field, message] of Object.entries(errors)) {
        refFormObserver.value.setErrors({ [field]: message[0] });
      }
    };

    const onSubmit = async () => {
      let isValid = refFormObserver.value.validate();
      if (!isValid) return;

      isLoading.value = true;

      const payload = {
        uid: router.currentRoute.params.id,
        employee: {
          edit_type: "contract_infos",
          grade: employee.value.grade,
          hire_date: employee.value.hire_date,
          salary: employee.value.salary,
          hierarchical_level: employee.value.hierarchical_level,
          contract_start_date: employee.value.contract_start_date,
          contract_end_date: employee.value.contract_end_date,
          employee_type: employee.value.employee_type,
        },
      };

      try {
        const response = await store.dispatch(
          "employee/updateEmployee",
          payload
        );

        if (!response.success) {
          handleErrors(response.errors);
          showToast(
            "Problème de la mise à jour de l'employé(e).",
            "AlertCircleIcon",
            "danger"
          );
          return;
        }
        handleSuccess();
      } catch (error) {
        showToast(
          "Une erreur est survenue lors de la mise à jour l'employé(e).",
          "AlertCircleIcon",
          "danger"
        );
      } finally {
        isLoading.value = false;
      }
    };

    watch(
      props.employeeData,
      () => {
        resetForm();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      employee,
      isLoading,
      hireDate,
      startDate,
      endDate,
      flatConfig,
      isContractor,
      refFormObserver,
      isLimitedContract,

      onSubmit,
      resetForm,
      getValidationState,
    };
  },
};
</script>
