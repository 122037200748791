<template>
  <component :is="employeeData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="employeeData === undefined">
      <h4 class="alert-heading">Erreur de récupération de données</h4>
      <div class="alert-body">
        Aucun employé trouvé pour cet ID. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-employees-list' }">
          la Liste du Personnel
        </b-link>
        pour d'autres employées.
      </div>
    </b-alert>

    <b-tabs ref="employeeTabsComponent" v-if="employeeData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Identification</span>
        </template>
        <employee-edit-tab-identification
          :employee-data="employeeData"
          @employee-updated="refreshEmployeeData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab :lazy="true">
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Infos Professionnelles</span>
        </template>
        <employee-edit-tab-information
          class="mt-2 pt-75"
          :employee-data="employeeData"
          @employee-updated="refreshEmployeeData"
        />
      </b-tab>

      <!-- Tab: Contract -->
      <b-tab :lazy="true">
        <template #title>
          <feather-icon icon="Edit2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Contrat</span>
        </template>
        <employee-edit-tab-contract
          class="mt-2 pt-75"
          :employee-data="employeeData"
          @employee-updated="refreshEmployeeData"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import EmployeeEditTabIdentification from "./EmployeeEditTabIdentification.vue";
import EmployeeEditTabInformation from "./EmployeeEditTabInformation.vue";
import EmployeeEditTabContract from "./EmployeeEditTabContract.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    EmployeeEditTabIdentification,
    EmployeeEditTabInformation,
    EmployeeEditTabContract,
  },
  setup() {
    const employeeData = ref(null);
    const employeeTabsComponent = ref(null);

    const getEmployee = () => {
      store
        .dispatch("employee/fetchEmployee", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          employeeData.value = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            employeeData.value = undefined;
          }
        });
    };
    getEmployee();

    const refreshEmployeeData = () => {
      // employeeTabsComponent.value;
      getEmployee();
    }

    return {
      employeeTabsComponent,

      refreshEmployeeData,
      employeeData,
    };
  },
};
</script>
